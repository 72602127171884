import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { FilterOptionType } from '../../../shared/models';
import { useSelector } from '../../../store/store';
import ActiveFilterItem from './ActiveFilterItem';
import s from './filters.module.scss';
import FiltersNew from './FiltersNew';
interface Props {
  activeFilters: FilterOptionType[];
  setActiveFilters: any;
  baseFiltersList: FilterOptionType[];
}
// var mapping = {
//   tenantId: { name: 'Table Name', type: 'string' },
//   status: { name: 'Status', type: 'option', options: [] },
//   uploadTime: { name: 'Upload Date', type: 'date' },
// };
const Filters: React.FC<Props> = ({
  setActiveFilters,
  activeFilters,
  baseFiltersList,
}) => {
  const versions = useSelector((state) => state.masterdata.versions);
  const tenantIds = useSelector((state) => state.documents.tenantIds);
  const structure = useSelector((state) => state.documents.structure);
  const [filterList, setFilterList] = useState<FilterOptionType[]>(baseFiltersList);

  useEffect(() => {
    if (tenantIds) {
      const mappedIds = tenantIds.map((e) => ({ id: e, name: e }));
      setFilterList((fl) => {
        const copy = cloneDeep(fl);
        const eli = copy.findIndex((e) => e.id === 'tenantId');
        copy[eli].options = mappedIds;
        return copy;
      });
    }
  }, [tenantIds, versions]);

  useEffect(() => {
    if (structure) {
      const tenantFilter = activeFilters.find((e) => e.id === 'tenantId');
      const mappedIds = [];
      structure?.tenants.forEach((tenant) => {
        if (tenantFilter && tenantFilter.value.value1 !== tenant.id) {
        } else {
          mappedIds.push(
            ...tenant.inboxes.map((inb) => ({ id: inb.id, name: inb.name }))
          );
        }
      });
      setFilterList((fl) => {
        const copy = cloneDeep(fl);
        const eli = copy.findIndex((e) => e.id === 'inboxId');
        if (eli !== -1) copy[eli].options = mappedIds;
        return copy;
      });
    }
  }, [activeFilters, structure, versions]);

  return (
    <div className={s.options}>
      {activeFilters.map((filter) => {
        return (
          <ActiveFilterItem
            key={filter.id}
            setActiveFilters={setActiveFilters}
            filter={filter}
          />
        );
      })}

      <FiltersNew
        activeFilters={activeFilters}
        filterList={filterList}
        setActiveFilters={setActiveFilters}
      />
    </div>
  );
};

export default Filters;
